import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { catchError, finalize, Observable, throwError, BehaviorSubject, timer } from "rxjs";
import { AuthUtils } from "./auth.utils";
import { debounce, tap } from 'rxjs/operators';

declare var $: any;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public requests = 0;
  public arrNoLoader = [
    '.svg',
    '/assets',
    '/svg',
    'AdminOfferGroupFlyerByIdGQL',
    'AdminOffersGroupGQL',
  ];
  private requestSubject = new BehaviorSubject<boolean>(false);
  private debounceLoader$ = this.requestSubject.pipe(
    debounce(show => show ? timer(0) : timer(1000))
  );

  constructor(private _authService: AuthService) {
    // Subscribe to the debounced loader observable
    this.debounceLoader$.subscribe(show => {
      this.toggleLoader(show);
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq = req.clone();
    let countLoaderBlock = 0;
    let newHeaders = req.headers;
    const culture = localStorage.getItem('culture') as string;

    if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken)) {
      newReq = req.clone({
        headers: newHeaders.set('Authorization', 'Bearer ' + this._authService.accessToken)
      });
    }

    if (req.body != null && req.body.operationName != null) {
      this.arrNoLoader.forEach(e => {
        if (req.body.operationName.indexOf(e) >= 0) {
          countLoaderBlock++;
        }
      });
    }

    if (countLoaderBlock === 0) {
      this.requests++;
      this.requestSubject.next(true);  // Trigger loader to show
    }

    return next.handle(newReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this._authService.signOut();
          location.reload();
        }
        return throwError(error);
      }),
      finalize(() => {
        if (countLoaderBlock === 0) {
          this.requests--;
          if (this.requests <= 0) {
            this.requests = 0;
            this.requestSubject.next(false);  // Trigger loader to hide after debounce time
          }
        }
      })
    );
  }

  toggleLoader(show: boolean): void {
    if (!show) {
      $('#loaderBox').stop().fadeOut();
    } else {
      $('#loaderBox').stop().fadeIn();
    }
  }
}
