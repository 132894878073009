import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject} from "rxjs";
import {Injectable} from "@angular/core";
import {httpHeaders} from "apollo-angular/headers";

@Injectable()
export class ServiceBase {

    public BASE_URL = environment.base_url;
    // private storeDataSource = new BehaviorSubject<Store>(new Store());
    // store = this.storeDataSource.asObservable();

    constructor(public http: HttpClient) {
    }

    public getToken() {
        return localStorage.getItem('accessToken') as string;
    }

    public header() {
        const customHeaders: HttpHeaders = new HttpHeaders();
        customHeaders.append('Content-Type', 'application/json');

        const token = localStorage.getItem('token') as string;

        if (token != null) {
            customHeaders.append('Authorization', 'Bearer ' + token);
        }
        return {headers: customHeaders};
    }

    // updateStore(param: Store) {
    //   this.storeDataSource.next(param);
    // }

    getExcel(model: any) {
        const url = `${this.BASE_URL}/store/search-store/excel`;
        return this.http.post(url, model);
    }

    getExcelSchedule(model: any) {
        const url = `${this.BASE_URL}/user-schedule/search-user-schedule/excel`;
        return this.http.post(url, model);
    }



}
