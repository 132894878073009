import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {BaseComponent} from "../../core/base.component";
import {AuthService} from "../../core/auth/auth.service";
import {User} from "../../../generated/graphql";

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent extends BaseComponent implements OnInit {

  modelUser: User = new User();
  language = super.accessLanguage.length === 0 ? 'pt-BR' : super.accessLanguage;

  constructor(public router: Router,
              public translate: TranslateService,
              public authService: AuthService)
  {
    super(router, translate);
  }

  ngOnInit(): void {
    super.handlerCultureLanguage(this.language);

    this.authService.user.subscribe((data) => {
      console.log(data)
      if (!this.isNullOrUndefined(data?._id)) {
        this.modelUser = data as User;
      }
    });

  }

  onLogout() {
    this.authService.signOut();
    this.router.navigate(['/login']);
    console.log('logout')
    // window.location.reload();
  }
}
